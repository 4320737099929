<template>
    <div class="grid">  
		<div class="col-12"> 
			<div class="card"> 
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start> 
                        <h3>Manage {{module_name}}</h3>  
                    </template>
					<template v-slot:end>
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" />   
                        <!-- <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Generate New'" :label="showBtnLabels ? 'Generate New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" />  -->
                        
                    </template> 
				</Toolbar>  
                <!-- <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" />
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/> 
                        <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" />
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar>  -->
				<DataTable :resizableColumns="true" columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple" class="p-datatable-gridlines p-datatable-sm"  :rows="10" dataKey="id" :rowHover="true" 
					v-model:filters="filters" filterDisplay="menu" :loading="loading" :filters="filters" responsiveLayout="scroll"
					:globalFilterFields="['name','company', 'invoice_number','amount','sent_date','due_date','invoice_status']" :rowsPerPageOptions="[10,20,50,100]" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:selection="selectedRecords" selectionMode="multiple"
                    :scrollable="true" scrollHeight="400px"> 
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">  
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </template> 
                    <template #empty> 
                        <div class="table-message"> 
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found" >
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template> 
                    
                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header" :exportable="col.field == 'action' ? false:true" :sortable="col.field == 'action' ? false:true" :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction':''" :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;':'max-width:5rem;flex-grow:1; flex-basis:160px;'"> 
                        <template  #body="{data}">
                            <img  v-if="col.field == 'company' && data.logo" :alt="data.company" :src="filesUrl+'companies/'+data.logo" class="tableProfileImg mr-2" />
                            <img  v-if="col.field == 'company' && !data.logo" :alt="data.company" :src="filesUrl+'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company'">{{data.company}}</span>
                            <span v-if="col.field == 'invoice_number'">{{data.invoice_number}}</span> 
                            <span v-if="col.field == 'amount'">
                                <span v-if="data.amount">{{userData['company_currency_code']}} {{Number(data.amount).toFixed(priceRounding)}}</span>
                                <span v-else>{{userData['company_currency_code']}} 0.00</span>
                            </span> 
                            <span v-if="col.field == 'paid_amount'">
                                <span v-if="data.paid_amount">{{userData['company_currency_code']}} {{Number(data.paid_amount).toFixed(priceRounding)}}</span>
                                <span v-else>{{userData['company_currency_code']}} 0.00</span>
                            </span> 
                            <span v-if="col.field == 'balance'">
                                <span v-if="data.balance">{{userData['company_currency_code']}} {{Number(data.balance).toFixed(priceRounding)}}</span>
                                <span v-else>{{userData['company_currency_code']}} 0.00</span>
                            </span> 
                            <span v-if="col.field == 'due_date'">{{formatDate(data.due_date)}}</span> 
                            <span v-if="col.field == 'sent_date'">
                                <span v-if="data.sent_date">{{formatDate(data.sent_date)}}</span>
                                <span v-else>
                                     <Tag :class="'mr-2 status-Blocked'" icon="pi pi-check" :severity="'danger'" :value="'Not Sent'"></Tag>
                                </span>
                            </span> 
                            <span v-if="col.field == 'id'"> 
                                <Button label="Download Invoice" class="p-button-raised p-button-secondary" :loading="downloadingInvoice[data.id]" @click="downloadInvoice(data.id)"/>
                            </span> 
                            <span v-if="col.field == 'invoice_status'">
                                <Tag class="mr-2" :icon="invoiceStatuses[data.invoice_status].icon" :severity="invoiceStatuses[data.invoice_status].color" :value="data.invoice_status"></Tag>
                                <!-- <Chip severity="success" label="Action" :class="'p-button-raised p-button-'+invoiceStatuses[data.invoice_status].color+' mr-2 mb-2'"/> -->
                                <!-- <SplitButton :invoiceId="data.id" @click="changeSelectedInvoiceId($event)" :label="data.invoice_status" :icon="invoiceStatuses[data.invoice_status].icon" :model="invoiceAvailableStatuses" :class="'p-button-raised p-button-'+invoiceStatuses[data.invoice_status].color+' mr-2 mb-2'"></SplitButton> -->
                            </span>  
                            
                            <span v-else-if="col.field == 'action'">
                                <div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text" :orderId="data.order_id" :recordId="data.id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions" ></Menu>
                                </div>  
                            </span>
                        </template>
                        <template #filter="{filterModel}">
                            <span v-if="col.field == 'company'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by company name"/>
                            </span> 
                            <span v-if="col.field == 'invoice_number'"> 
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Invoice Number"/>
                            </span>   
                            <span v-else-if="col.field == 'amount'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Amount"/>
                            </span> 
                            <span v-if="col.field == 'due_date'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search Due Date"/>
                            </span> 
                            <span v-if="col.field == 'sent_date'">
                                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search Sent Date"/>
                            </span>  
                            <span v-else-if="col.field == 'status'">
                                <Dropdown v-model="filterModel.value" :options="invoiceStatuses" placeholder="Any" class="p-column-filter" :showClear="true">
                                    <template #value="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.value" v-if="slotProps.value">{{slotProps.value}}</span>
                                        <span v-else>{{slotProps.placeholder}}</span>
                                    </template>
                                    <template #option="slotProps">
                                        <span :class="'customer-badge status-' + slotProps.option">{{slotProps.option}}</span>
                                    </template>
                                </Dropdown>
                            </span>  
                        </template>   
                    </Column>   
				</DataTable>
			</div>
		</div>
        <Sidebar v-model:visible="showStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="showStatusChangedForm=false" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @submit.prevent="login" @keydown="invoiceStatusForm.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Invoice#{{selectedRecordId}}</h3> 
                        <p>Update invoice status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                   <h4>Invoice status is being changed to <span style="color:red">"{{invoiceStatusForm.status}}"</span></h4>
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                    <label><h5>Status Change Description</h5></label>
                                    <Textarea placeholder="Description" v-model="invoiceStatusForm.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="invoiceStatusForm" field="description" />
                                </div>  
                            </div>  
                        </div>  
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check" @click="changeOrderInvoiceStatus()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="showStatusChangedForm=false"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
        <Sidebar v-model:visible="showInvoicePaymentForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="showInvoicePaymentForm=false" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @submit.prevent="login" @keydown="invoicePaymentForm.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <h3 style="width:100%" class="mb-0">Invoice#{{invoiceData.invoice_number}}  <p style="font-size:12px;">Manage Invoice Payment</p> </h3> <br>
                              
                            </template>
                            <template v-slot:end>  
                                <div>
                                    <div class="bg-yellow p-3" style="cursor:pointer" @click="showTransectionPage=true">Customer Balance : {{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} <strong><span v-if="customerData.balance"> {{Number(customerData.balance).toFixed(priceRounding)}}</span> <span v-else> 00.00 </span></strong> </div>
                                </div>
                            </template> 
                        </Toolbar>    
                    </div> 
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                   <h4>Add Invoice Payment</h4>
                                </div>  
                                <div class="col-12 lg:col-3 sm:col-12 mb-2" style="float:left"> 
                                    <label><h6>Payment Amount</h6></label>
                                    <span class="pink-color">
                                        <div class="p-inputgroup">
                                            <span class="p-inputgroup-addon">{{userData['company_currency_code']}}</span>
                                            <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : defaultCurrency" locale="en-US"  placeholder="Tax Price" v-model="invoicePaymentForm.paid_amount" :min="0" showButtons class="full-width"/> 
                                        </div>
                                    </span> 
                                    <HasError class="p-error" :form="invoicePaymentForm" field="paid_amount" />
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label><h6>Description/Notes</h6></label> 
                                    <InputText placeholder="Notes" v-model="invoicePaymentForm.description" class="full-width" /> 
                                    <HasError class="p-error" :form="invoicePaymentForm" field="description" />
                                </div>  
                                <div class="col-12 lg:col-2 sm:col-12 mb-2 mt-5" style="float:left"> 
                                     <Button type="submit" label="Save Payment" :loading="savingInvoicePayment" icon="pi pi-check" @click="saveInvoicePayment()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                </div>
                            </div>  
                        </div>   
                        <Divider /> 
                        <div class="col-12 lg:col-12 flex  justify-content-center">
                            <div class="p-5 bg-yellow"><h6>Total</h6><br>
                                <strong>
                                    <h4 v-if="invoiceData.amount" style="margin-top:-20px">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number(invoiceData.amount).toFixed(priceRounding)}}</h4>
                                    <h4 v-else style="margin-top:-20px">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} 0.00</h4>
                                </strong>
                            </div>
                            <div class="p-5 bg-purple"><h6>Paid</h6><br>
                                <strong>
                                    <h4 v-if="totalPaid" style="margin-top:-20px">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{totalPaid.toFixed(priceRounding)}}</h4>
                                    <h4 v-else style="margin-top:-20px">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} 0.00</h4>
                                </strong>
                            </div>
                            <div class="p-5 bg-orange"><h6>Payable</h6><br>
                                <strong>
                                    <h4 v-if="totalPaid && invoiceData.amount" style="margin-top:-20px">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((Number(invoiceData.amount) - totalPaid)).toFixed(priceRounding)}}</h4> 
                                    <h4 v-else style="margin-top:-20px">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{Number((Number(invoiceData.amount) - totalPaid)).toFixed(priceRounding)}}</h4>
                                </strong>
                            </div> 
                        </div>
                        <div class="col-12 lg:col-12">
                            <div class="col-12 md:col-6 lg:col-3" style="float:left" v-for=" (payment, key) in invoicePayments " :key="key" >
                                <div class="card overview-box flex flex-column pt-2" :class="payment.payment_status == 'canceled' ? 'bg-red' : 'bg-green'" >
                                    <div class="flex align-items-center muted-text"> 
                                        <h6 v-if="payment.payment_status" :class="payment.payment_status == 'canceled' ? 'mb-2 mt-3' : 'm-0'"  >{{payment.payment_status.charAt(0).toUpperCase() + payment.payment_status.slice(1)}}</h6>
                                        <div v-if="payment.payment_status != 'canceled'" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}" style="width:100%; text-align:right">
                                            <Button icon="pi pi-ellipsis-h" :id="payment.id" class="p-button-rounded p-button-text p-button-plain" @click="togglePaymentMenu"></Button>
                                            <Menu ref="PaymentMenu" :popup="true" :model="PaymentMenuItems"></Menu>
                                        </div>
                                    </div>
                                    <div class="flex justify-content-between mt-3 flex-wrap">
                                        <div class="flex flex-column" style="width: 180px;">
                                            <span class="mb-1 fs-xlarge">{{userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'}} {{payment.paid_amount.toFixed(priceRounding)}}</span>
                                            <span v-if="payment.modified_by" class="overview-status p-1 teal-bgcolor fs-small">{{formatDateTime(payment.date_modification)}} By {{(payment.modified_by)}}</span>
                                            <span v-else class="overview-status p-1 teal-bgcolor fs-small">{{formatDateTime(payment.date_added)}} By {{(payment.added_by)}}</span>
                                            <span class="mb-1"><p>{{(payment.description)}}</p></span>
                                        </div>
                                        <div class="flex align-items-end">
                                            <!-- <Chart ref="overviewChartData2" type="line" :data="overviewChartData2" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0"> 
                            <template v-slot:end> 
                                <Button type="button" label="Cancel"  icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="showInvoicePaymentForm=false"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
        <Toast />
        <ConfirmDialog group="dialog" />
        <ConfirmDialog group="dialog1" ></ConfirmDialog>
        <ConfirmDialog group="dialog2" ></ConfirmDialog>
        <!-- form loading -->
        <!-- <OrderInvoiceSchedule v-on:invoiceFormClose="showInvoiceSchedule=false" v-if="showInvoiceSchedule  && (permissions[54].includes('insert') || permissions[54].includes('update'))" :orderId="orderId" :recordId="selectedRecordId" :showForm="true" />  
        <OrderInvoiceForm v-on:formClose="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :orderId="orderId" :recordId="selectedRecordId" :showForm="true" /> -->
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        <CustomerTransections v-on:closeTransectionPage="closeTransectionPage" v-if="showTransectionPage  && (permissions[47] && permissions[47].includes('view') )" :customer_id="customer_id" :showTransectionSidebar="true" /> 
        <InvoiceHistory v-on:closeHistoryPage="showHistoryPage=false" v-if="showHistoryPage  && (permissions[53] && permissions[53].includes('view') )" :invoice_id="selectedRecordId" :showHistorySidebar="true" /> 
   </div>
</template>
<script>
 
import CustomerTransections from '../../pages/Customers/CustomerTransections.vue';
import InvoiceHistory from '../../pages/Orders/InvoiceHistory.vue'; 
//import OrderInvoiceForm from '../Orders/OrderInvoiceForm.vue'; 
// import OrderInvoiceSchedule from '../Orders/OrderSchedules.vue'; 
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                invoiceStatusForm: new this.Form({ 
                    id:0,
                    description:'',    
                    status:'',
                    invoice_for_id:0,
                    invoice_for:'order'
                }),
                invoicePaymentForm: new this.Form({ 
                    id:0,
                    invoice_for_id:0,
                    invoice_for:'order',
                    description:'',    
                    paid_amount:0,
                    invoice_id:0, 
                }),
                PaymentMenuItems : [
                    {label: 'Cancel Payment', icon: 'pi pi-fw pi-trash',command: () => { this.cancelInvoicePayment(); }}, 
                ],
                module_name:'Order Invoices', 
                priceRounding:2,
                actions: [], 
                moduleId:43,
                showHelp : false, 
                isSaving:false, 
                isStatusSaving:false,
                showHistoryPage:false,
                showInvoiceSchedule:false,
				records: null, 
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                selectedRecordId: 0,  
                totalPaid:0,
                saveAndClose:false,  
                savingInvoicePayment:false,
                showTransectionPage:false,
                invoiceStatuses: [],
                downloadingInvoice:[],
                invoicePayments:[],
                invoiceData:[],
                customerData:[],
                showStatusChangedForm:false,
                showInvoicePaymentForm:false,
                selectedPaymentId:0,
                customer_id: 0,
                orderId:0,
			}
		}, 
        props:{ 
            customerId :{
                type : Number,
            },
            customerEmail :{
                type : String,
            },
        }, 
        components:{
            //OrderInvoiceForm, 
            CustomerTransections,
            InvoiceHistory,
            // OrderInvoiceSchedule
        },
		created() {   
            this.customer_id = this.customerId;
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push(
                    {label:'Pending', icon:'pi pi-eye-slash',command: () => { this.changeInvoiceStatus('Pending'); }},
                    {label:'Seen', icon:'pi pi-eye',command: () => { this.changeInvoiceStatus('Seen'); }},
                    {label:'Confirmed', icon:'pi pi-check',command: () => { this.changeInvoiceStatus('Confirmed'); }},
                    {label:'Paid', icon:'pi pi-check-circle',command: () => { this.changeInvoiceStatus('Paid'); }}, 
                    {label:'Canceled', icon:'pi pi-times',command: () => { this.changeInvoiceStatus('Canceled'); }},
                    {separator:true},
                    {label:'Resend Email', icon:'pi pi-envelope',command: () => { this.changeInvoiceStatus('ResendMail'); }},
                    //{label:'Create Copy', icon:'pi pi-copy',command: () => { this.changeInvoiceStatus('Copy'); }},
                    // {label:'Create Schedules', icon:'pi pi-clock',command: () => { this.changeInvoiceStatus('Schedules'); }},
                    {separator:true},
                    {label:'Payments', icon:'pi pi-money-bill',command: () => { this.changeInvoiceStatus('Payments'); }},
                    {separator:true},
                    {label:'View History', icon:'pi pi-history',command: () => { this.changeInvoiceStatus('ViewHistory'); }},
                );
            }
            // if(this.checkPermission(this.moduleId,'delete')){
            //     this.actions.push({
			// 			label: 'Delete',
			// 			icon: 'pi pi-times',
            //             command: () => {
            //                 this.getClickedRecord('delete');
            //             }
			// 		});
            // }   
			this.initFilters();
            this.columns = [
                {field: 'action', header: 'Action'}, 
                {field: 'invoice_number', header: 'Invoice #'},    
                {field: 'amount', header: 'Total Payable'},    
                {field: 'paid_amount', header: 'Total Paid'},    
                {field: 'balance', header: 'Balance'},    
                {field: 'invoice_status', header: 'Invoice Status'},    
                {field: 'due_date', header: 'Due Date'}, 
                {field: 'sent_date', header: 'Sent Date'},  
                {field: 'id', header: 'Download'},  
            ];
            this.selectedColumns = this.columns;
		},
        computed: { 
        },
		mounted() { 
            this.priceRounding = this.userData['company_price_rounding'] ? this.userData['company_price_rounding'] : 2,

            this.invoiceStatuses['Pending'] = {label:'Pending', icon:'pi pi-eye-slash', color:'warning'}; 
            this.invoiceStatuses['Seen'] = {label:'Seen', icon:'pi pi-eye', color:'primary'};
            this.invoiceStatuses['Confirmed'] = {label:'Confirmed', icon:'pi pi-check', color:'info'};
            this.invoiceStatuses['Paid'] = {label:'Paid', icon:'pi pi-check-circle', color:'success'};
            this.invoiceStatuses['Canceled'] =  {label:'Canceled', icon:'pi pi-times', color:'danger'};
			this.getRecords();   
		},
		methods: {   

            closeTransectionPage(){  
                this.showTransectionPage = false;
                this.getInvoicePayments();
            }, 

            cancelInvoicePayment(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog1',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {   
                        this.axios.post('cancelInvoicePayment',{id:this.selectedPaymentId})
                        .then( (response) => {  
                            let responseStatus = this.printResponseResult(response); 
                            if(responseStatus == 200){  
                                Vthis.getInvoicePayments();
                            }
                        })
                        .catch((error) => {    
                            this.printResponseResult(error);
                        })
                        .finally(() => {  
                        })  
                    },
                    reject: () => { 
                    }
                }); 
            },
 
            async changeOrderInvoiceStatus(){    
                var Vthis = this; 
                Vthis.invoiceStatusForm.id = Vthis.selectedRecordId;
                Vthis.invoiceStatusForm.invoice_for_id = Vthis.orderId;
                this.isStatusSaving = true;
                await this.invoiceStatusForm
                .post(Vthis.$baseurl+'api/changeInvoiceStatus', Vthis.invoiceStatusForm)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response); 
                    if(responseStatus == 200){ 
                        Vthis.getRecords();
                        Vthis.invoiceStatusForm.reset();  
                        Vthis.records.filter( (record) => {
                            if(record.id == Vthis.invoiceStatusForm.id){
                                record.invoice_status = Vthis.invoiceStatusForm.status;
                            } 
                        })  
                    } 
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isStatusSaving = false; 
                }) 
            }, 
 
            togglePaymentMenu(event) { 
                this.selectedPaymentId = event.currentTarget.getAttribute('id'); 
                this.$refs.PaymentMenu.toggle(event);
            },
            async downloadInvoice($id){
                this.downloadingInvoice[$id] = true;
                var Vthis = this; 
                await this.axios.post('downloadInvoice',{id:$id})
                .then( (response) => {  
                    window.open(Vthis.$baseurl+response.data.link, '_blank').focus();  
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                   this.downloadingInvoice[$id] = false;
                })  
            },
 
            async changeInvoiceStatus(status){ 
                if(status == 'Pending'){
                    this.invoiceStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.invoiceStatusForm.status = 'Pending';
                }
                else if(status == 'Seen'){
                    this.invoiceStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.invoiceStatusForm.status = 'Seen';
                }
                else if(status == 'Confirmed'){
                    this.invoiceStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.invoiceStatusForm.status = 'Confirmed';
                }
                else if(status == 'Paid'){
                    this.invoiceStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.invoiceStatusForm.status = 'Paid';
                }
                
                else if(status == 'Canceled'){
                    this.invoiceStatusForm.reset();
                    this.showStatusChangedForm = true; 
                    this.invoiceStatusForm.status = 'Canceled';
                } 
                else if(status == 'Payments'){
                    this.getInvoicePayments();
                    this.invoicePaymentForm.reset();
                    this.showInvoicePaymentForm = true;  
                }
                else if(status == 'ResendMail'){
                    this.resendInvoiceMail(); 
                }
                else if(status == 'Copy'){ 
                    //this.showForm = true;
                }
                else if(status == 'ViewHistory'){ 
                    this.showHistoryPage = true;
                }
                // else if(status == 'Schedules'){ 
                //     // this.showInvoiceSchedule = true;
                // } 
            },

           
            resendInvoiceMail(){ 
                var Vthis = this;  
                this.$confirm.require({
                    group: 'dialog2',
                    header: 'Confirmation',
                    message: 'Are you sure you want to proceed?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        let message = 'Sending mail...'
                        Vthis.showMessage(message,'info',55000);  
                        Vthis.axios.post('resendInvoiceMail',{id:Vthis.selectedRecordId})
                        .then( (returnedData) => { 
                            Vthis.$toast.removeAllGroups();
                            Vthis.printResponseResult(returnedData); 
                     
                        })
                        .catch((error) => {    
                            this.printResponseResult(error); 
                        })
                        .finally(() => {  
                        })  
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                });  
            },
            
            
            async saveInvoicePayment(){     
                var Vthis = this; 
                Vthis.invoicePaymentForm.invoice_for_id = Vthis.orderId;
                Vthis.invoicePaymentForm.invoice_id = Vthis.selectedRecordId;  
                this.savingInvoicePayment = true;
                await this.invoicePaymentForm
                .post(Vthis.$baseurl+'api/saveInvoicePayment', Vthis.invoicePaymentForm)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        Vthis.invoicePaymentForm.reset();
                        Vthis.showMessage('Payment has been saved successfully','success'); 
                        Vthis.getInvoicePayments();
                        Vthis.showStatusChangedForm = false;
                    } 
                })
                .catch((error) => { 
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.savingInvoicePayment = false; 
                }) 
            },

            getInvoicePayments(){ 
                var Vthis = this; 
                Vthis.totalPaid = 0;
                this.axios.post('getAllInvoicePayments',{invoice_id:this.selectedRecordId,invoice_for_id:this.orderId,invoice_for:'order'})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.customerData = response.data.customerBalance;   
                        this.invoiceData = response.data.invoice_data;   
                        this.invoicePayments = response.data.invoicePayments;   
                        Vthis.invoicePayments.forEach( (payment) => {
                            if(payment.payment_status == 'successfull'){
                                Vthis.totalPaid = Vthis.totalPaid + payment.paid_amount;
                            } 
                        })
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 

            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.viewRecord();
                }  
                else if(action == 'update'){
                    this.showForm = true; 
                }
            },

            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
                this.orderId = event.currentTarget.getAttribute('orderId');  
				this.$refs.menu.toggle(event);
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true;
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.showForm = false;
            }, 
            getRecords(){  
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderInvoices',{customer_id:this.customer_id})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.records = response.data;  
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            }, 
            getInvoicRecord(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllOrderInvoices',{id:this.selectedRecordId})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.printResponseResult(response,false); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error); 
                })
                .finally(() => { 
                })  
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        let DeleteAbleRecords = [];
                         
                        if(Vthis.selectedRecords){
                            Vthis.selectedRecordId = '';
                            Vthis.selectedRecords.forEach((data) => {
                                DeleteAbleRecords.push(data.id);
                            })
                        } 
                        else{
                            DeleteAbleRecords.push(Vthis.selectedRecordId);
                        } 
                         
                        // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                        // filterdRecords.forEach((data) => {
                        //     DeleteAbleRecords.push(data.id);
                        // })  

                        Vthis.axios.post('deleteOrderProduct',{id:DeleteAbleRecords})
                        .then( (response) => {   
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){ 
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            onToggle(value) {
                this.selectedColumns = this.columns.filter(col => value.includes(col));
            },
            
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'invoice_number': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'amount': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'paid_amount': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'balance': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'sent_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'due_date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'invoice_status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
					'company': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}, 
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
