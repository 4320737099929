<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-6 lg:col-6 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Your Company</label>
                                    <Dropdown CompanyLoading :filter="true" :showClear="true"  v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id"  :placeholder="CompanyLoading ? 'Loading...' : 'Select Company'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div> 
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Customer Company</label>
                                    <AutoComplete  v-model="form.customer_company" :suggestions="filteredCustomerComapnies" @complete="searchCustomerCompany($event)" field="customer_company"  class="_full-width"/>
                                    <HasError class="p-error" :form="form" field="customer_company" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Customer Category<span v-if="permissions[30].includes('insert')" class="supporting-btn-link" @click="showCustomerCategoryForm=true;">+ Add New</span></label>
                                    <Dropdown CustomerCategoriesLoading :filter="true" :showClear="true"  v-model="form.category_id" :options="CustomerCategories" optionLabel="name" optionValue="id"  :placeholder="CustomerCategoriesLoading ? 'Loading...' : 'Select Category'" class="full-width" style="float:left"/>
                                    <HasError class="p-error" :form="form" field="category_id" />
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-12 mb-2 "  style="float:left"> 
                                    <label>Credit Limit</label>
                                    <InputNumber id="credit_limit" v-model="form.credit_limit"  showButtons mode="currency" currency="USD"  class="full-width" style="float:left"/>
                                    <HasError class="p-error" :form="form" field="credit_limit" /> 
                                </div> 
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Customer Email</label>
                                    <AutoComplete :item-select="selectedCustomer()" v-model="form.email" :suggestions="filteredCustomers" @complete="searchCustomers($event)" field="email"  class="_full-width"/>
                                    <HasError class="p-error" :form="form" field="email" />
                                </div>
                                <!-- <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Email</label>
                                    <InputText id="email" placeholder="Email ID#" v-model="form.email"  type="email" class="full-width" style="float:left"/>
                                    <HasError class="p-error" :form="form" field="email" /> 
                                </div> -->
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Phone</label>
                                    <InputText id="phone" placeholder="Phone" v-model="form.phone"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="phone" /> 
                                </div> 
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Address</label>
                                    <InputText id="address" placeholder="Address" v-model="form.address"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="address" /> 
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Website</label>
                                    <InputText id="website" placeholder="website" v-model="form.website"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="website" /> 
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Facebook</label>
                                    <InputText id="facebook" placeholder="facebook" v-model="form.facebook"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="facebook" /> 
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Skype</label>
                                    <InputText id="skype" placeholder="skype" v-model="form.skype"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="skype" /> 
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>  
                                <div class="col-12 lg:col-12 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30" class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>   
                            </div> 
                             <div class="col-12 lg:col-12 sm:col-12 md:col-12">  
                                <Panel class="mb-3 full-width" :header="'Settings'" :toggleable="true"  style="float:left" >  
                                    <div> 
                                        <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">  
                                            <label>Email Sending </label>
                                            <Dropdown v-model="form.email_sending" :options="EmailSendingOptions" optionLabel="name" optionValue="id" placeholder="Select Option" class="full-width"/>
                                        </div> 
                                    </div> 
                                </Panel>  
                            </div> 
                        </div>  
                        <div class="col-6 lg:col-6 sm:col-12 md:col-12"> 
                            <div v-for="(contact,key) in form.customerContacts" :key="key"> 
                                <Panel class="mb-3 full-width" :header="'Contact Person - '+ (key+1) " :toggleable="true"  style="float:left" >  
                                    <div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>First Name</label>
                                            <InputText id="customer_contact_names" placeholder="First Name" v-model="form.customerContacts[key].first_name" :value="contact.first_name"  type="text" class="full-width"/>
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Surname</label>
                                            <InputText id="customer_contact_surnames" placeholder="Surname" v-model="form.customerContacts[key].surname" :value="contact.surname"  type="text" class="full-width"/>
                                        </div> 
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Email</label>
                                            <InputText id="customer_contact_email" placeholder="Email" v-model="form.customerContacts[key].email" :value="contact.email"  type="email" class="full-width"/>
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Phone</label>
                                            <InputText id="customer_contact_phone" placeholder="Phone" v-model="form.customerContacts[key].phone" :value="contact.phone"  type="text" class="full-width"/>
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Mobile</label>
                                            <InputText id="customer_contact_Mobile" placeholder="Mobile" v-model="form.customerContacts[key].mobile" :value="contact.mobile"  type="text" class="full-width"/>
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Position</label>
                                            <InputText id="customer_contact_Position" placeholder="Position" v-model="form.customerContacts[key].position" :value="contact.position"  type="text" class="full-width"/>
                                        </div>
                                        <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">  
                                            <label>Address</label>
                                            <InputText id="customer_address" placeholder="Address" v-model="form.customerContacts[key].address" :value="contact.address"  type="text" class="full-width"/>
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Show on Job Sheet</label>
                                            <Dropdown v-model="form.customerContacts[key].show_on_job_sheet" :options="ShowOnJobSheetOptions" optionLabel="name" optionValue="id" placeholder="Select Option" class="full-width"/>
                                        </div>
                                        <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                            <label>Is it billing contact?</label>
                                            <Dropdown v-model="form.customerContacts[key].billing_contact" :options="BillingContactOptions" optionLabel="name" optionValue="id" placeholder="Select Option" class="full-width"/>
                                        </div>   
                                        <div class="col-2 lg:col-2 sm:col-12 mb-2 mt-3" style="float:left">  
                                            <Button @click="form.customerContacts.splice(key,1)" v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete'"  icon="pi pi-trash"  class="p-button-lg p-button-raised p-button-danger mr-2"  style="padding: 8px 20px;"/> 
                                        </div>
                                    </div> 
                                </Panel> 
                            </div>
                            <div class="col-12 lg:col-12 sm:col-12 mb-12 mt-3 mb-3" style="float:left">
                                <Button @click="addCustomerContactItem()" v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New Contact'" :label="showBtnLabels ? 'Add New Contact' : ''" icon="pi pi-plus"  class="p-button-raised p-button-success mt-2 mr-2 float-right" /> 
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <CustomerCategoryForm v-on:formClose="closeCustomerCategoryForm" v-if="showCustomerCategoryForm && (permissions[30].includes('insert'))" :recordId="0" :showForm="true" /> 
</template>
<script> 
import CustomerCategoryForm from '../../components/Customers/CustomerCategoryForm.vue';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    company_id: 0, 
                    name: '',   
                    customer_company:'',
                    category_id: 0,   
                    credit_limit: 0,   
                    facebook: '',   
                    skype: '',   
                    website: '',       
                    email: '',       
                    phone: '',    
                    address:'',    
                    status:1, 
                    description: '',  
                    customerContacts:[],  
                    email_sending:1,
                }),   
                customer_company:null,
                CompanyLoading:false, 
                CustomerCompanyLoading:false, 
                CustomerCategoriesLoading:false,
                module_name:'Customer',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                moduleId:31,
                loadingComponent:true,
                formVisible:false, 
                companies:[], 
                taxRates:[],
                allCustomers:[], 
                filteredCustomers:[],
                CustomerCategories:[],
                customerCompanies:[],
                filteredCustomerComapnies:[],
                ShowOnJobSheetOptions:[{name:'Yes', id:1},{name:'No', id:0}],
                BillingContactOptions:[{name:'Yes', id:1},{name:'No', id:0}],
                EmailSendingOptions:[{name:'Active', id:1},{name:'Blocked', id:0}],
                showCustomerCategoryForm:false,
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
        components:{
            CustomerCategoryForm
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: { 
            closeCustomerCategoryForm(){
                this.getCustomerCategories();
                this.showCustomerCategoryForm = false;
            },  
            addCustomerContactItem(){  
                this.form.customerContacts.push({'first_name' : '', 'surname' : '', 'email' : '', 'phone' : '', 'mobile' : '', 'position' : '','address':'', 'show_on_job_sheet' : '', 'billing_contact' : '' }); 
            }, 
            searchCustomerCompany(event) {
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.filteredCustomerComapnies = [...this.customerCompanies];
                    }
                    else {
                        this.filteredCustomerComapnies = this.customerCompanies.filter((company) => {
                            return company.customer_company.toLowerCase().startsWith(event.query.toLowerCase());
                        });
                    }
                }, 250);
            }, 
            selectedCustomer(){
                if(event){ 
                    if(this.form.email.email){ 
                        this.recId = this.form.email.id;
                        this.form.email = this.form.email.email;  
                        this.updateRecord();
                    } 
                }
            },
            searchCustomers(event) {  
                setTimeout(() => {
                    if (!event.query.trim().length) {
                        this.filteredCustomers = [...this.allCustomers];
                    }
                    else { 
                        this.filteredCustomers = this.allCustomers.filter((customer) => {
                            if(customer.email){
                                return customer.email.toLowerCase().startsWith(event.query.toLowerCase());
                            }
                            return false;
                        });
                    }
                }, 1000); 
            },  
            getAllRequestData(){   
                this.$axios.all([this.getCompanies()])   
                .then(() => {      
                    this.getCustomerCategories();   
                    this.getCustomerCompanies();
                    this.getAllCustomers();
                }) 
                .then(() => { 
                    this.updateRecord();
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
            async getCustomerCompanies(){ 
                var Vthis = this;
                Vthis.CustomerCompanyLoading = false;
                await this.axios.post('getAllCustomers', {customer_company:true})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.customerCompanies = response.data;   
                    } 
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CustomerCompanyLoading = false;
                })  
            }, 
            async getAllCustomers(){ 
                var Vthis = this;
                Vthis.CustomerCompanyLoading = false;
                await this.axios.post('getAllCustomers')
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.allCustomers = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CustomerCompanyLoading = false;
                })  
            }, 
            async getCompanies(){ 
                var Vthis = this;
                Vthis.CompanyLoading = true;
                await this.axios.post('getAllCompanies')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CompanyLoading = false;
                })  
            },
            
            async getCustomerCategories(){ 
                var Vthis = this;
                Vthis.CustomerCategoriesLoading = true;
                await this.axios.post('getAllCustomerCategories')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.CustomerCategories = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error); 
                })
                .finally(() => { 
                    Vthis.CustomerCategoriesLoading = false;
                })  
            }, 
              
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await this.form
                .post(Vthis.$baseurl+'api/saveCustomer', Vthis.form)
                .then((response) => {  
                    let responseStatus = this.printResponseResult(response); 
                    if(responseStatus == 200){
                        if(this.saveAndClose){   
                            setTimeout( () => {this.closeForm();},1000);
                            this.saveAndClose = false; 
                        }   
                    }    
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                    // let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    // Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('CustomerformClose',true); 
            },
             
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllCustomers',{id:this.recId})
                    .then( (response) => {   
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]); 
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },     
		},
        watch : {
             
        }
}
</script>
 
