<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            
            <div class="mt-0 mb-0">
                <div class="col-12 lg:col-12 mb-0">
                    <h3 class="mb-0">{{module_name}}</h3> 
                    <p>You can view the complete information of a customer</p>
                </div>
                <Divider class="mt-0" />
                <div class="grid modal-body full-page-modal">  
                    <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                        <TabView @tab-click="tabClicked($event)" lazy class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                            <TabPanel >
                                <template #header>
                                    <i class="pi pi-info-circle"></i>
                                    <span>&nbsp; Basic Details</span>
                                </template>
                                <div> 
                                    <Panel  class="col-12 lg:col-12 sm:col-12 mb-3" header="" :toggleable="false" style="float:left">
                                        <!-- <div class="col-12 lg:col-2 sm:col-2 mb-2 profileCardImg" style="float:left"> 
                                            <div class="mb-2" v-if="user.profile_pic">
                                                <img alt="Profile Picture" :src="profilePic" >
                                            </div> 
                                            <div>
                                                
                                            </div>
                                        </div>  -->
                                        <div class="col-12 lg:col-12 sm:col-9 mb-3" style="float:left">
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Name: &nbsp;</strong><span v-if="user.name">{{user.name}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Email: &nbsp;</strong><span v-if="user.email">{{user.email}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Phone: &nbsp;</strong><span v-if="user.phone">{{user.phone}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Company: &nbsp;</strong><span v-if="user.company_name">{{user.company_name}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Website: &nbsp;</strong><span v-if="user.website">{{user.website}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Facebook: &nbsp;</strong><span v-if="user.facebook">{{user.facebook}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Skype: &nbsp;</strong><span v-if="user.skype">{{user.skype}}</span><span v-else>N/A</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Category: &nbsp;</strong><span v-if="user.category_name">{{user.category_name}}</span><span v-else>N/A</span></div> 
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Date Created: &nbsp;</strong><span v-if="user.date_added">{{formatDateTime(user.date_added)}}</span><span v-else>N/A</span></div> 
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Created By: &nbsp;</strong><span v-if="user.added_by">{{user.added_by}}</span><span v-else>N/A</span></div> 
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Last Modified: &nbsp;</strong><span v-if="user.modified_by">{{user.modified_by}}</span><span v-else>N/A</span></div> 
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Last Modification Date: &nbsp;</strong><span v-if="user.date_modification">{{formatDateTime(user.date_modification)}}</span><span v-else>N/A</span></div> 
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float:left"> <strong>Profile Status: &nbsp;</strong> 
                                                <span>
                                                    <Tag v-if="user.status" class="mb-2 font-14" severity="success" value="Active" rounded></Tag>
                                                    <Tag v-else class="mb-2 font-14" severity="danger" value="InActive" rounded></Tag> 
                                                </span>
                                            </div> 
                                            <div class="col-12 lg:col-12 sm:col-12 mb-2 "  style="float:left"> <strong>Description: &nbsp;</strong><span v-if="user.description">{{user.description}}</span><span v-else>N/A</span></div>
                                            
                                            <div class="col-12 lg:col-12 sm:col-12 mb-2 p-0 no-pad" v-if="user.customerContacts && Object.keys(user.customerContacts).length" style="float:left"> 
                                                <div class="col-12 lg:col-6 sm:col-12 mb-2" v-for="(contact,key) in user.customerContacts" :key="key" style="float:left">
                                                    <div class="card col-12 lg:col-12 sm:col-12 mb-2" style="float:left"> 
                                                        <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"><strong>Name: &nbsp;</strong><span v-if="contact.first_name">{{contact.first_name}} {{contact.surname}}</span><span v-else>N/A</span></div>
                                                        <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"><strong>Email: &nbsp;</strong><span v-if="contact.first_name">{{contact.email}}</span><span v-else>N/A</span></div>
                                                        <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"><strong>Phone: &nbsp;</strong><span v-if="contact.first_name">{{contact.phone}}</span><span v-else>N/A</span></div>
                                                        <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"><strong>Mobile: &nbsp;</strong><span v-if="contact.first_name">{{contact.mobile}}</span><span v-else>N/A</span></div>
                                                        <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"><strong>Position: &nbsp;</strong><span v-if="contact.first_name">{{contact.position}}</span><span v-else>N/A</span></div>
                                                        <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"><strong>Is Billing Contact: &nbsp;</strong><span v-if="contact.first_name">{{contact.billing_contact==1? 'Yes' : 'No'}}</span><span v-else>N/A</span></div>
                                                        <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left"><strong>Address: &nbsp;</strong><span v-if="contact.first_name">{{contact.address}}</span><span v-else>N/A</span></div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel> 
                                </div>
                            </TabPanel> 
                            <TabPanel   :disabled="tabDisabled">
                                <template #header> 
                                    <i class="pi pi-car"></i>
                                    <span>&nbsp; Orders Placed</span>  
                                </template> 
                                <div> 
                                    <CustomerOrders  :customerId="recId" :customerEmail="customerEmail" /> 
                                </div>
                            </TabPanel> 
                            <TabPanel   :disabled="tabDisabled">
                                <template #header> 
                                    <i class="pi pi-car"></i>
                                    <span>&nbsp; Invoices</span>  
                                </template> 
                                <div> 
                                    <CustomerOrderInvoices  :customerId="recId"   /> 
                                </div>
                            </TabPanel> 
                            <TabPanel  :disabled="tabDisabled">
                                <template #header> 
                                    <i class="pi pi-dollar"></i>
                                    <span>&nbsp; Transactions</span>  
                                </template> 
                                <div>  
                                    <CustomerTransactions   :customer_id="recId"  /> 
                                </div>
                            </TabPanel> 
                        </TabView>  
                            
                    </div> 
                    
                </div>
            </div> 
           
        </Sidebar> 
    </BlockUI>
</template>
<script> 

import CustomerTransactions from './CustomerTransactions.vue';
import CustomerOrderInvoices from './CustomerOrderInvoices.vue';
import CustomerOrders from './CustomerOrders.vue';
export default {
    data() {
			return {   
                user:[],
                module_name:'Customer Details',  
                recId:0, 
                formVisible:false, 
                customerEmail:'',  
			}
		},   
        props:{
            
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            email :{
                type : String,
            }, 
        },
        components:{
            CustomerOrders,
            CustomerOrderInvoices,
            CustomerTransactions
        },
		created() {  
            this.customerEmail = this.email;  
            this.recId = this.recordId;
		},
        computed: { 
        },
		mounted() {   
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.updateRecord();  
            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                         
                        if(parameter.view == 'view-customer-tab'){
                           this.activeTab = parameter.id;
                        } 
                    })
                }
            }
		},
		methods: { 
            tabClicked($event){   
                // console.log($event.index);
                this.removeViewFromURL('view-customer-tab');
                this.addViewInURL({view: 'view-customer-tab', id:this.activeTab}); 
            },
              
            closeForm(){ 
                this.removeViewFromURL('view-customer-tab');
                this.$emit('CustomerProfileClose',true); 
            }, 
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllCustomers',{id:this.recId})
                    .then( (response) => {    
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            Vthis.user = response.data[0];
                            this.module_name = Vthis.user.name;
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;  
                        }
                    })
                    .catch((error) => {  
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
		},
        watch : {
             
        }
}
</script>
 
